import { useEffect, useState } from "react";
import { Link, Outlet, unstable_usePrompt, useLocation, useNavigate, useRouteLoaderData } from "@remix-run/react";
import Logo from "~/components/Logo";
import HIVE from "~/images/hive.svg";
import { Divider, VerticalMarquee } from "~/components/auth/Utilities";
import HiveCreateAccountModal from "~/components/auth/HiveCreateAccountModal";
import SocialSignup from "~/components/auth/SocialSignup";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "~/utils/firebase";
import { getAuth } from "firebase/auth";
import { loadHivePrivateKey } from "~/utils/hive";
import { PrivateKey } from "@hiveio/dhive";
import Popover from "~/components/Popover";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpLong, faCheck } from "@fortawesome/free-solid-svg-icons";
import { loadTranslator } from "~/utils/loadTranslator";

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export interface HiveAccountKeys {
  public: {
    posting: string;
    active: string;
    ownerKey: string;
    memo: string;
  };
  private: {
    ownerKey: string;
    activeKey: string;
    postingKey: string;
  };
  memo: string;
  password: string;
}

export function generateKeys(username: string, password: string): HiveAccountKeys {
  let opts = {
    addressPrefix: "STM",
    chainId: "beeab0de00000000000000000000000000000000000000000000000000000000"
  };

  const ownerKey = PrivateKey.fromLogin(username, password, "owner");
  const activeKey = PrivateKey.fromLogin(username, password, "active");
  const postingKey = PrivateKey.fromLogin(username, password, "posting");
  const memoKey = PrivateKey.fromLogin(username, password, "memo").createPublic(opts.addressPrefix).toString();

  const keys = {
    posting: postingKey.createPublic(opts.addressPrefix).toString(),
    active: activeKey.createPublic(opts.addressPrefix).toString(),
    ownerKey: ownerKey.createPublic(opts.addressPrefix).toString()
  };

  return {
    public: {
      ...keys,
      memo: memoKey
    },
    private: {
      ownerKey: ownerKey.toString(),
      activeKey: activeKey.toString(),
      postingKey: postingKey.toString()
    },
    memo: memoKey,
    password
  };
}

export const generateFileFromText = (generatedKeys: any, username: string) => {
  return `
    Welcome the the world of InLeo!\n
    Never share your private keys with anyone and never lose your private keys, there's no way to access them again. \n
    Keys of account ${username}.
    Active Private Key: ${generatedKeys.private.activeKey}
    Active Public Key: ${generatedKeys.public.active}\n
    Posting Private Key: ${generatedKeys.private.postingKey}
    Posting Public Key: ${generatedKeys.public.posting}\n
    Owner Private Key: ${generatedKeys.private.ownerKey}
    Owner Public Key: ${generatedKeys.public.ownerKey}\n
    Memo Key: ${generatedKeys.memo}\n
    Master Password: ${generatedKeys.password}
  `;
};

export default function Signup() {
  const location = useLocation();
  const navigate = useNavigate();

  const [hiveCreateAccountModal, setHiveCreateAccountModal] = useState(false);

  useEffect(() => {
    window.onbeforeunload = () => ({});

    return () => {
      window.onbeforeunload = undefined;
    };
  }, [location]);

  const t = loadTranslator(useRouteLoaderData("root").translations);

  if (location.pathname === "/signup/success") return <Outlet />;

  return (
    <div className="flex flex-col flex-1 w-full h-full pt-16 pb-8 overflow-y-auto">
      {/* logo */}
      <Link to="/" className="fixed top-4 left-8 w-12 h-12">
        <Logo className="text-pri dark:text-pri-d origin-left scale-[3.525] pointer-events-none" noText />
      </Link>

      {/* vertical marquee */}
      <VerticalMarquee />
      <div className="flex flex-1 flex-col justify-center items-center sm:items-start pl-0 sm:pl-28 lg:pl-40">
        <div className="flex flex-col w-fit h-full max-w-full sm:max-w-[540px] px-4 gap-y-6">
          {/* heading */}
          <div className="flex flex-col mb-5">
            <h1 className="text-[clamp(38px,4vw,62px)] font-black !font-display uppercase leading-none whitespace-nowrap">
              join inleo
            </h1>
            <p className="text-[clamp(11.25px,1.185vw,18.375px)] font-black !font-display uppercase text-pri/40 dark:text-pri-d/40 leading-none whitespace-nowrap">
              microblogging on the blockchain
            </p>
          </div>

          {/* keystore */}
          <div id="keystore-help" className="relative flex flex-1 w-full group px-0.5 max-w-sm mx-auto">
            <Link
              to={`/keystore${location.search}`}
              className="peer flex flex-1 justify-center items-center h-11 px-5 gap-x-2 rounded-full text-sm font-semibold bg-pri text-pri cursor-pointer z-10 hover:bg-pri transition-all duration-300 opacity-100 hover:opacity-85 disabled:brightness-50 disabled:hover:opacity-100 disabled:cursor-not-allowed"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="#000" width="26px" height="26px" viewBox="0 0 56 56">
                <path d="M 26.6875 12.6602 C 26.9687 12.6602 27.1094 12.4961 27.1797 12.2383 C 27.9062 8.3242 27.8594 8.2305 31.9375 7.4570 C 32.2187 7.4102 32.3828 7.2461 32.3828 6.9648 C 32.3828 6.6836 32.2187 6.5195 31.9375 6.4726 C 27.8828 5.6524 28.0000 5.5586 27.1797 1.6914 C 27.1094 1.4336 26.9687 1.2695 26.6875 1.2695 C 26.4062 1.2695 26.2656 1.4336 26.1953 1.6914 C 25.3750 5.5586 25.5156 5.6524 21.4375 6.4726 C 21.1797 6.5195 20.9922 6.6836 20.9922 6.9648 C 20.9922 7.2461 21.1797 7.4102 21.4375 7.4570 C 25.5156 8.2774 25.4687 8.3242 26.1953 12.2383 C 26.2656 12.4961 26.4062 12.6602 26.6875 12.6602 Z M 15.3438 28.7852 C 15.7891 28.7852 16.0938 28.5039 16.1406 28.0821 C 16.9844 21.8242 17.1953 21.8242 23.6641 20.5821 C 24.0860 20.5117 24.3906 20.2305 24.3906 19.7852 C 24.3906 19.3633 24.0860 19.0586 23.6641 18.9883 C 17.1953 18.0977 16.9609 17.8867 16.1406 11.5117 C 16.0938 11.0899 15.7891 10.7852 15.3438 10.7852 C 14.9219 10.7852 14.6172 11.0899 14.5703 11.5352 C 13.7969 17.8164 13.4687 17.7930 7.0469 18.9883 C 6.6250 19.0821 6.3203 19.3633 6.3203 19.7852 C 6.3203 20.2539 6.6250 20.5117 7.1406 20.5821 C 13.5156 21.6133 13.7969 21.7774 14.5703 28.0352 C 14.6172 28.5039 14.9219 28.7852 15.3438 28.7852 Z M 31.2344 54.7305 C 31.8438 54.7305 32.2891 54.2852 32.4062 53.6524 C 34.0703 40.8086 35.8750 38.8633 48.5781 37.4570 C 49.2344 37.3867 49.6797 36.8945 49.6797 36.2852 C 49.6797 35.6758 49.2344 35.2070 48.5781 35.1133 C 35.8750 33.7070 34.0703 31.7617 32.4062 18.9180 C 32.2891 18.2852 31.8438 17.8633 31.2344 17.8633 C 30.6250 17.8633 30.1797 18.2852 30.0860 18.9180 C 28.4219 31.7617 26.5938 33.7070 13.9140 35.1133 C 13.2344 35.2070 12.7891 35.6758 12.7891 36.2852 C 12.7891 36.8945 13.2344 37.3867 13.9140 37.4570 C 26.5703 39.1211 28.3281 40.8321 30.0860 53.6524 C 30.1797 54.2852 30.6250 54.7305 31.2344 54.7305 Z" />
              </svg>
              {t("signup-with-keystore")}
            </Link>

            <span className="absolute top-0 left-0 w-full h-11 rounded-full bg-gradient-to-r from-[#4ac9e7] via-[#8e55df] to-[#f49545] blur z-[1] transition-all duration-300 peer-disabled:opacity-50" />
            <span className="absolute -top-1.5 -right-4 flex items-center gap-x-1 py-0.5 px-1.5 rounded-full bg-green-400 text-pri text-xxs uppercase font-semibold z-10 shadow-[0px_0px_8px_rgb(74_222_128_/_55%)]">
              <FontAwesomeIcon icon={faCheck} size="sm" />
              <span>BEST</span>
            </span>

            <Tooltip
              anchorSelect="#keystore-help"
              className="max-w-sm !rounded-xl !py-2 !px-3 !opacity-100 bg-pri dark:bg-pri-d border border-pri dark:border-pri-d shadow-[0_0_12px_3px_rgb(255_255_255_/_12%)]"
              clickable={true}
              delayShow={100}
              render={() => (
                <div className="text-pri/70 dark:text-pri-d/70 text-xs leading-normal">
                  Keystore is a text file that is stored in your device. We recommend this option as it is the most
                  secure and easy way to use INLEO. Once created, this file will be used to sign into your account.
                  INLEO is a blockchain-based social media platform and this file is your crypto wallet. When you earn
                  rewards, you can use this wallet to claim them in any cryptocurrency you want. You can learn more
                  about keystore wallets{" "}
                  <Link
                    to="https://docs.inleo.io/tools-and-resources/how-to-guides/inleo-the-basics/how-to-join-inleo/how-to-signup-with-keystore"
                    target="_blank"
                    rel="noreferrer noopenner"
                    className="inline-flex items-center gap-x-1 text-pri dark:text-pri-d font-bold hover:underline"
                  >
                    <span>Here</span>
                    <FontAwesomeIcon icon={faArrowUpLong} size="sm" className="rotate-45" />
                  </Link>
                </div>
              )}
            />
          </div>

          <Divider />

          {/* social section */}
          <SocialSignup />

          <Divider />

          {/* hive section */}
          <div className="flex items-center gap-x-2">
            <button
              type="button"
              className="flex justify-center items-center w-fit mx-auto px-5 py-2 gap-x-3 rounded-full border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-colors duration-150"
              onClick={() => setHiveCreateAccountModal(true)}
            >
              <img src={HIVE} alt="Hive" className="flex shrink-0 w-7 h-7" />
              <span className="text-sm font-medium">{t("create-hive-account")}</span>
            </button>

            {false && (
              <button
                type="button"
                className="flex justify-center items-center w-fit mx-auto pl-3 px-5 gap-x-0.5 rounded-full border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-colors duration-150"
                onClick={() => navigate("/keystore")}
              >
                <svg
                  id="Capa_1"
                  data-name="Capa 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1000 1000"
                  height={42}
                  width={42}
                >
                  <defs>
                    <style>
                      {`
        .cls-1 {
          fill: currentColor;
        }
          `}
                    </style>
                  </defs>
                  <polygon
                    className="cls-1"
                    points="758.66 303.94 700.99 385.34 582.33 455.39 601.62 570.2 500.05 639.55 398.48 570.2 417.76 455.39 299.1 385.34 241.41 303.94 447.36 369.02 425.03 535.52 500.05 564.73 575.06 535.52 552.74 369.02 758.66 303.94"
                  />
                  <polygon
                    className="cls-1"
                    points="500.05 639.55 438.99 673.19 298.92 570.2 222.9 297.24 204.3 289.64 165.67 519.67 500.05 790.36 834.39 519.67 795.74 289.64 777.19 297.24 701.11 570.2 561.09 673.19 500.05 639.55"
                  />
                </svg>
                <span className="text-sm font-medium">{t("create-keystore-file")}</span>
              </button>
            )}
          </div>

          <div className="flex flex-col gap-y-2.5 mt-4">
            <strong className="font-semibold">{t("already-have-an-account")}</strong>
            <Link
              to="/login"
              role="button"
              title="Sign in"
              aria-label="Sign in"
              className="flex items-center justify-center w-fit py-2.5 px-8 rounded-full border border-acc text-acc text-sm font-medium hover:bg-acc/[.075] transition-colors duration-150"
            >
              {t("sign-in")}
            </Link>
          </div>
        </div>
      </div>

      <HiveCreateAccountModal visibility={hiveCreateAccountModal} setVisibility={setHiveCreateAccountModal} />
    </div>
  );
}
